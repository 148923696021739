/* The default button */
button.AppBtn {
  background-color: var(--color-bkg-btn-default);
  font-family: inherit;
  font-size: var(--font-small);
  text-align: center;
  border: 1px solid var(--color-border);
  padding: .3em 1em .3em 1em;
  border-radius: 3px;
  outline: none;
}

/* https://stackoverflow.com/questions/23885255/how-to-remove-ignore-hover-css-style-on-touch-devices
 */
 @media (hover: hover) {
  button.AppBtn:hover:enabled {
    background-color: var(--color-bkg-btn-hover);
    transition: all .3s;
  }  
}

button.AppBtn:focus {
  border: 1px solid var(--color-fg-btn-focus);
}

button.AppBtn:active {
  transform: scale(.9);
  transition: all .2s;
}

button.AppBtn:disabled {
  background-color: var(--color-bkg-btn-default);
  color: var(--color-fg-btn-disabled);
  border: 1px solid var(--color-text-disabled);
}

/* Overrides/additions for special attention button */
button.AppBtn-accent {
  background-color: var(--color-bkg-btn-accent);
  color: var(--color-text-inverted);  
}

@media (hover: hover) {
  button.AppBtn-accent:hover:enabled {
    background-color: var(--color-bkg-btn-accent-hover);
  }  
}

button.AppBtn-accent:focus {
  border: 1px solid var(--color-border-accent);
}

/* .App button.app-accented-btn:disabled {
  TBD/TODO
} */

/* Overrides/additions for special "menu" button */
button.AppBtn-menu {
  background-color: rgba(0,0,0,0); /*transparent*/
  border: 1px solid rgba(0,0,0,0);    
  color: var(--color-text-inverted);
  text-decoration: underline;
  font-size: var(--font-tiny);
  margin: .1em;
  padding: .2em;
}

@media (hover: hover) {
  button.AppBtn-menu:hover:enabled {
    background-color: var(--color-bkg-btn-accent-hover);
    border: 1px solid var(--color-border);
    text-decoration: none;
  }  
}

button.AppBtn-menu:focus {
  background-color: var(--color-bkg-btn-accent-hover);
  border: 1px solid var(--color-fg-btn-focus);
  text-decoration: none;
}

button.AppBtn-menu:disabled {
  background-color: rgba(0,0,0,0);
  color: var(--color-text-disabled);
}


/* Overrides/additions for button with SVG icon instead of text */
button.AppBtn-svg {
  background-color: rgba(0,0,0,0); /* transparent */
  width: 2em;
  height: 2em;
  min-width: 2em;
  min-height: 2em;
  padding:0;
  border: none;
}

button.AppBtn-svg > svg {
  margin: .1em;
  stroke: var(--color-text-info);
  stroke-width: .2em;
  pointer-events: none;
}

@media (hover: hover) {
  button.AppBtn-svg:hover:enabled {
    stroke: var(--color-fg-btn-hover);
    background-color: rgba(0,0,0,0);
    cursor: pointer;
  }
  
  button.AppBtn-svg:hover:enabled > svg {
      margin: 0;
      transition: all .3s;
  }   
}

button.AppBtn-svg:focus {
  border: none;
}

button.AppBtn-svg:focus > svg {
  stroke: var(--color-fg-btn-focus);
}

button.AppBtn-svg:focus:enabled > svg {
  margin: 0;
}

button.AppBtn-svg:disabled {
  background-color: rgba(0,0,0,0);
  border: none;
}

button.AppBtn-svg:disabled > svg {
  stroke: var(--color-fg-btn-disabled);
}
