.Alert-Heading {
  display: flex;
  align-items: center;
  margin-bottom: .5em;
}
.Alert-Heading > svg {
  min-width: 2em;
  max-width: 2em;
  stroke-width: .2em;
}

.Alert-Heading > h3 {
  margin: 0;
  margin-left: .5em;
}

.AlertModal > p {
  white-space: pre-wrap;
}

.AlertModal > .AppBtn {
  margin-top: .5em;
}

.Alert-CodeBlock {
  margin: 1em 0;
  padding: .5em;
  border: 1px solid var(--color-border-accent);
}

.Alert-Info svg {
  stroke: var(--color-text-info);
}

.Alert-Warning svg {
  stroke: var(--color-text-warning);
  fill: var(--color-text-warning);
}

.Alert-Error svg {
  stroke: var(--color-text-error);
  fill: var(--color-text-error)
}