.AppHeader {
  display: flex;
  width: 100%;
  height: var(--height-header);
  top: 0;
  position: fixed;
  z-index: 1000;
  flex-direction: row;
  justify-content: space-between;
  align-items:flex-start;
  background-color: var(--color-bkg-btn-accent);
  box-shadow: 0px 2px 3px var(--color-border);
  padding: 0 .25em .25em .25em;
}

.AppHeader > nav {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:flex-start;
  height: 100%;
}

.AppHeader-home-logo {
  display: flex;
  flex-direction: row;
  align-items:flex-end;
  margin: 0 0 0 .1em;
}

/* Dirty short-cut for this "special" button* (will also affect the logo) */
.AppHeader-home-logo svg {
  stroke: white !important;
}

/* The "logo" */
.AppHeader-home-logo > svg {
  /* border: 1px dotted red; */
  margin: 0 0 0 .25em;
  stroke-width: .08em;
  width: calc(4.3 * var(--font-big));
  height:auto;
}

/* Keep Home button smaller to make it better fit next to the logo */
.AppHeader-home-logo > button {
  margin-bottom: .21em;
  width: 1.7em;
  height: 1.7em;
  min-width: 1.7em;
  min-height: 1.7em;
} 

/* When Home button is hidden (already "at home") */
.AppHeader-home-logo > button {
  transform: translateX(-1.25em);
  opacity: 0;
  transition: all .3s ease-in-out;
}
.AppHeader-home-logo > svg {
  transform: translateX(calc( -1.15 * var(--font-big)));
  transition: transform .3s ease-in-out;
}

/* When the Home button is visible */
.AppHeader-home-visible > button {
  transform: translateX(0);
  opacity: 1;
}
.AppHeader-home-visible > svg {
  transform: translateX(0);
}
.AppHeader-about-pages span {
  font-size: var(--font-tiny);
  color: var(--color-border-light);
}
